import { useCallback, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import OpenInNew from '@mui/icons-material/OpenInNew'
import Divider from '@mui/material/Divider'
import ConfigRow from './ConfigRow'
import Checkbox from './Checkbox'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

const ChatbotEditor = ({ chatbot, onUpdateChatbot }) => {
  const [updatedChatbot, setUpdatedChatbot] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setUpdatedChatbot(JSON.parse(JSON.stringify(chatbot)))
  }, [chatbot])

  const updateField = useCallback(
    (field, e) => {
      setUpdatedChatbot((bot) => ({
        ...bot,
        [field]: e.target.value,
      }))
    },
    [setUpdatedChatbot]
  )

  const updateFieldInt = useCallback(
    (field, e) => {
      setUpdatedChatbot((bot) => ({
        ...bot,
        [field]: +e.target.value,
      }))
    },
    [setUpdatedChatbot]
  )

  const handleCancel = useCallback(() => setUpdatedChatbot(chatbot), [chatbot])

  const handleSave = useCallback(async () => {
    setIsUpdating(true)
    await onUpdateChatbot(updatedChatbot)
    setIsUpdating(false)
  }, [updatedChatbot, onUpdateChatbot])

  if (updatedChatbot === null) return null

  const hasUpdates = JSON.stringify(chatbot) !== JSON.stringify(updatedChatbot)

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& input[type="checkbox"]': {
          width: '16px',
          height: '16px',
          cursor: 'pointer',
          backgroundColor: 'background.paper',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'divider',
          appearance: 'none',
          '&:checked': {
            accentColor: (theme) => theme.palette.primary.main,
            appearance: 'revert',
          },
        },
        '& input[type="text"]': {
          backgroundColor: 'transparent',
          width: 50,
          borderWidth: 1,
          borderColor: 'divider',
          borderStyle: 'solid',
          px: 0.5,
          py: 0.125,
          borderRadius: '6px',
          textAlign: 'right',
        },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            fontSize: '0.9em',
            fontStyle: 'italic',
            textAlign: 'center',
            mb: 2,
            px: 2,
          }}
        >
          {chatbot.description}
        </Box>
        <ConfigRow
          title="enabled"
          content={
            <Checkbox
              checked={updatedChatbot.enabled}
              onChange={(e) =>
                setUpdatedChatbot({
                  ...updatedChatbot,
                  enabled: !updatedChatbot.enabled,
                })
              }
            />
          }
        />
        <ConfigRow
          title="openai config"
          content={
            <OpenInNew
              sx={{
                fontSize: '1.15em',
                cursor: 'pointer',
                '&:hover': { color: 'primary.main' },
              }}
              onClick={() => window.open(chatbot.assistant_url, '_blank')}
            />
          }
        />
        <ConfigRow
          title="max threads per user"
          content={
            <input
              type="text"
              value={updatedChatbot.max_threads_per_user}
              onChange={updateFieldInt.bind(null, 'max_threads_per_user')}
            />
          }
        />
        <ConfigRow
          title="max completion tokens"
          content={
            <input
              type="text"
              value={updatedChatbot.max_completion_tokens}
              onChange={updateFieldInt.bind(null, 'max_completion_tokens')}
            />
          }
        />
        <ConfigRow
          title="test message"
          content={<code>{chatbot.test_message}</code>}
        />
        {/* <ConfigRow
          title="deactivate after"
          Content={() => <Box>{chatbot.deactivate_after_minutes} minutes</Box>}
        /> */}

        <Box
          sx={{
            mt: 2,
            '& textarea': {
              backgroundColor: 'transparent',
              width: '100%',
              border: 1,
              borderRadius: '4px',
              borderColor: 'divider',
              p: 1,
              resize: 'none',
              height: 450,
              my: 1,
              fontSize: '0.9em',
            },
          }}
        >
          <Box sx={{ fontWeight: 700, fontSize: '0.9em' }}>context message</Box>
          <textarea
            value={updatedChatbot.additional_instructions}
            onChange={updateField.bind(null, 'additional_instructions')}
          ></textarea>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          disabled={!hasUpdates}
          sx={{ fontSize: '0.8em' }}
          variant="outlined"
          onClick={handleCancel}
        >
          cancel changes
        </Button>
        <LoadingButton
          loading={isUpdating}
          disabled={!hasUpdates}
          sx={{ fontSize: '0.8em' }}
          variant="contained"
          onClick={handleSave}
        >
          save changes
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default ChatbotEditor
