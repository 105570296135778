import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/harsh`

export const createReclip = async ({ title, username }) => {
  try {
    const url = `${BASE_URL}/reclips`
    const { data } = await axios.put(url, { title, username })
    return data.source_id
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const sendJustClipped = async ({ sourceId }) => {
  try {
    const url = `${BASE_URL}/reclips/${sourceId}/send_just_clipped`
    const { data } = await axios.put(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
