import { useState, useEffect, useCallback, useRef } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import Search from 'components/Main/Search'
import Divider from '@mui/material/Divider'
import Filters from './Filters'
import ListItem from './ListItem'
import Story from './Story'
import useToast from 'hooks/useToast'
import Modals from './modals'
import useModalsActions from 'store/actions/modals'
import { coverPhotoTypes, itemStatuses } from './const'
import Chat from './Chat'
import Publishing from './Publishing'

const TeamReclipStory = () => {
  const [items, setItems] = useState([])
  const toast = useToast()
  const [filters, setFilters] = useState({
    share_code: '',
    text: '',
    start_date: null,
    end_date: null,
    min_words: 5,
    cover_photo_types: [coverPhotoTypes.STILL, coverPhotoTypes.LIVE],
    consent_to_publish: false,
    has_animated_video: false,
  })
  const [settings, setSettings] = useState(null)
  const [staffUsers, setStaffUsers] = useState(null)
  const { openModal } = useModalsActions()
  const [chatItem, setChatItem] = useState(null)
  const searchRef = useRef(null)
  const [isPublishing, setIsPublishing] = useState(false)
  const [publishError, setPublishError] = useState(null)

  useEffect(() => {
    api.teamReclipStory.getStoryItems().then(setItems)
  }, [])

  const getStoryMeta = useCallback(() => {
    api.teamReclipStory.getStoryMeta().then((meta) => {
      setSettings(meta.settings)
      setStaffUsers(meta.staff_users)
    })
  }, [])

  useEffect(getStoryMeta, [getStoryMeta])

  const setItemStatus = useCallback(
    (itemId, status) => {
      api.teamReclipStory.setItemStatus(itemId, status).then((items) => {
        setItems(items)

        // update share-draft-with-staff
        // NOTE: this assumes a change to the status on the backend
        const draftItems = items.filter((item) =>
          [itemStatuses.DRAFT_ADD, itemStatuses.DRAFT_DELETE].includes(
            item.status
          )
        )
        if (draftItems.length === 0)
          setSettings((s) => ({
            ...s,
            share_draft_with_staff: false,
          }))
        searchRef.current.updateHits({ [itemId]: { status } })
      })
    },
    [setSettings]
  )

  const deleteItem = useCallback(
    (itemId) => {
      api.teamReclipStory.deleteItem(itemId).then(() => {
        toast({ message: 'item deleted' })
      })
    },
    [toast]
  )

  const updateDraftOrder = useCallback((itemIds) => {
    api.teamReclipStory.updateDraftOrder(itemIds).then((items) => {
      setItems(items)
    })
  }, [])

  const publish = useCallback(
    (sendNotification) => {
      setIsPublishing(true)
      api.teamReclipStory
        .publish(sendNotification)
        .then((items) => {
          setItems(items)
          getStoryMeta()
          toast({ message: 'The story is published.' })
          setIsPublishing(false)

          searchRef.current.updateAllHits({
            status: itemStatuses.SHARED_WITH_TR,
          })
          setTimeout(() => {
            const updates = items.reduce((p, c) => {
              p[c.id] = { status: c.status }
              return p
            }, {})
            searchRef.current.updateHits(updates)
          })
        })
        .catch((e) => {
          setPublishError(e.toString())
        })
    },
    [toast, getStoryMeta]
  )

  const saveConsent = useCallback(
    (consentGiven) => {
      setChatItem(null)
      api.teamReclipStory
        .setConsentToPublish(chatItem.id, consentGiven)
        .then((items) => {
          setItems(items)
          searchRef.current.updateHitsFunc((hit) => {
            if (hit.share.reclip.source_id === chatItem.share.reclip.source_id)
              return { ...hit, consent_to_publish: consentGiven }
            else return hit
          })
        })
    },
    [chatItem]
  )

  const editReclip = useCallback(
    async (shareCode, editorUserId) => {
      try {
        const newReclip = await api.reclips.editReclip(shareCode, editorUserId)
        const title = newReclip.title || newReclip.title_auto || '<no title>'
        const editor = staffUsers.find((u) => u.id === editorUserId)
        toast({
          message: `The clip "${title}" is now in @${editor?.username}'s library.`,
        })
      } catch (e) {
        toast({ message: e.message, severity: 'error' })
      }
    },
    [toast, staffUsers]
  )

  const openEditModal = useCallback(
    (shareCode) => {
      openModal('pickEditor', {
        staffUsers,
        onConfirm: (user) => editReclip(shareCode, user),
      })
    },
    [staffUsers, openModal, editReclip]
  )

  const setShareDraftWithStaff = useCallback(
    async (shareDraft) => {
      setSettings((settings) => ({
        ...settings,
        share_draft_with_staff: shareDraft,
      }))

      try {
        await api.teamReclipStory.setShareDraftWithStaff(shareDraft)
        const message = shareDraft
          ? 'The draft is now visible to staff users.'
          : 'The draft is now hidden from staff users.'
        toast({ message })
      } catch (e) {
        toast({ message: e.message, severity: 'error' })
        setSettings((settings) => ({
          ...settings,
          share_draft_with_staff: !shareDraft,
        }))
      }
    },
    [toast]
  )

  if (settings === null) return null

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          p: 4,
          position: 'relative',
        }}
      >
        <Chat
          storyItem={chatItem}
          closeChat={setChatItem.bind(null, null)}
          onSave={saveConsent}
        />
        <Box
          sx={{
            height: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Filters filters={filters} setFilters={setFilters} />
          <Divider sx={{ borderWidth: 2 }} />
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <Search
              ref={searchRef}
              ListItem={(props) => (
                <ListItem
                  setItemStatus={setItemStatus}
                  editReclip={openEditModal}
                  openChat={setChatItem}
                  deleteItem={deleteItem}
                  {...props}
                />
              )}
              search={api.teamReclipStory.searchStoryItems}
              exportResults={api.teamReclipStory.exportStoryItems}
              filters={filters}
            />
          </Box>
        </Box>
        <Divider orientation="vertical" sx={{ mx: 4 }} />
        <Box sx={{ height: '100%', width: 400 }}>
          {isPublishing ? (
            <Publishing publishError={publishError} />
          ) : (
            <Story
              items={items}
              setItemStatus={setItemStatus}
              updateDraftOrder={updateDraftOrder}
              publish={publish}
              shareDraftWithStaff={settings.share_draft_with_staff}
              setShareDraftWithStaff={setShareDraftWithStaff}
            />
          )}
        </Box>
      </Box>
      <Modals />
    </>
  )
}

export default TeamReclipStory
