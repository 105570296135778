import Box from '@mui/material/Box'

const ConfigRow = ({ title, content, sx = {} }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '0.9em',
      height: 30,
      ...sx,
    }}
  >
    <Box sx={{ fontWeight: 700 }}>{title}</Box>
    {content}
  </Box>
)

export default ConfigRow
