import { useEffect, useState, useCallback } from 'react'
import { useModal } from 'store/selectors'
import useModalsActions from 'store/actions/modals'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import ReactJson from 'react-json-view'
import Loader from 'components/core/Loader'

const JsonViewer = () => {
  const {
    isOpen,
    params: { dataPromise, loaderText },
  } = useModal('jsonViewer')
  const { closeModal } = useModalsActions()
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!dataPromise) return
    dataPromise.then(setData)
  }, [dataPromise])

  const onClose = useCallback(() => {
    setData(null)
    closeModal('jsonViewer')
  }, [closeModal])

  if (!isOpen) return null

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      sx={{ cursor: 'pointer' }}
      PaperProps={{
        style: {
          backgroundImage: 'none',
          boxShadow: 'none',
          width: '800px',
          height: '90vh',
          padding: '20px',
          position: 'relative',
          cursor: 'default',
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {data ? (
          <ReactJson
            src={data}
            theme="harmonic"
            style={{ padding: '8px', height: '100%', overflow: 'auto' }}
            collapsed={3}
            displayDataTypes={false}
            enableClipboard={false}
          />
        ) : (
          <Loader text={loaderText} />
        )}
      </Box>
    </Dialog>
  )
}

export default JsonViewer
