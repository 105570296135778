import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Threads from './Threads'
import useChatbots from './useChatbots'
import ChatbotSelect from './ChatbotSelect'
import ChatbotEditor from './ChatbotEditor'

const Chatbots = () => {
  const { chatbots, selectedChatbot, onUpdateChatbot, onSelectChatbot } =
    useChatbots()

  if (!chatbots || !selectedChatbot) return null

  return (
    <Box
      sx={{
        p: 4,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          minWidth: 400,
          width: 400,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ChatbotSelect
          chatbots={chatbots}
          selectedChatbot={selectedChatbot}
          onSelectChatbot={onSelectChatbot}
        />
        <Divider sx={{ my: 2 }} />
        <Box sx={{ flex: 1 }}>
          <ChatbotEditor
            chatbot={selectedChatbot}
            onUpdateChatbot={onUpdateChatbot}
          />
        </Box>
      </Box>
      <Divider orientation="vertical" sx={{ mx: 4 }} />
      <Threads chatbotSlug={selectedChatbot.slug} />
    </Box>
  )
}

export default Chatbots
