import { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import LoadingIcon from 'components/core/LoadingIcon'
import RefreshIcon from '@mui/icons-material/Refresh'
import Divider from '@mui/material/Divider'
import PhoneTable from './PhoneTable'
// import useModalsActions from 'store/actions/modals'

const FreePhoneNumbers = () => {
  const [phones, setPhones] = useState(null)
  const [loadingPhones, setLoadingPhones] = useState(false)
  // const { openModal } = useModalsActions()

  const getPhones = useCallback(async () => {
    setLoadingPhones(true)
    const phones = await api.freePhoneNumbers.getFreePhoneNumbers()
    setPhones(phones)
    setLoadingPhones(false)
  }, [])

  const deleteUser = useCallback(async (userId) => {
    const data = await api.users.deleteUser(userId)
    console.log(data)
  }, [])

  useEffect(() => {
    getPhones()
  }, [getPhones])

  if (!phones) return null

  console.log(phones)

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 800,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            py: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 0,
          }}
        >
          <Box sx={{ fontSize: '1.2em', fontWeight: 700 }}>
            Free Phone Numbers
          </Box>
          <Box>
            <LoadingIcon
              Icon={RefreshIcon}
              isLoading={loadingPhones}
              onClick={getPhones}
            />
          </Box>
        </Box>
        <Divider />
        <Box sx={{ flex: 1, position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflow: 'auto',
              py: 1,
            }}
          >
            <PhoneTable phones={phones} deleteUser={deleteUser} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FreePhoneNumbers
