import Box from '@mui/material/Box'
import CoverPhoto from '../CoverPhoto'
import { toDateString } from 'services/utils'
import TooltipIconButton, { TooltipIcon } from 'components/core/Tooltip'
import ConsentedIcon from '@mui/icons-material/Verified'
import CopyToClipboardButton from 'components/core/CopyToClipboard'
import WatchIcon from '@mui/icons-material/Visibility'
import RemoveIcon from '@mui/icons-material/Remove'
import UndoIcon from '@mui/icons-material/History'
import useModalsActions from 'store/actions/modals'
import MoveUpIcon from '@mui/icons-material/ArrowUpward'
import MoveDownIcon from '@mui/icons-material/ArrowDownward'
import AnimatedIcon from '@mui/icons-material/ColorLens'
import { itemStatuses } from '../const'

const ListItem = ({ item, setItemStatus, moveUp, moveDown }) => {
  const { id, share, status, consent_to_publish, published_at } = item
  const title = share.reclip.title || share.reclip.title_auto
  const { openModal } = useModalsActions()

  const openWindow = (url) => window.open(url, '_blank')

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ flex: 0, mr: 2 }}>
        <CoverPhoto reclip={share.reclip} sizeRatio={0.6} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>@{share.reclip.username}</Box>
          <TooltipIcon
            Icon={ConsentedIcon}
            title={
              consent_to_publish
                ? 'user has consented to publication'
                : 'user has not consented to publication'
            }
            sx={{
              color: consent_to_publish ? 'aqua' : undefined,
              fontSize: '0.95em',
              opacity: consent_to_publish ? 1 : 0.3,
              ml: '6px',
            }}
          />
          {share.has_animated_video && (
            <TooltipIcon
              Icon={AnimatedIcon}
              title="has animated video"
              onClick={openWindow.bind(null, share.video_url)}
              sx={{
                color: '#00ff14',
                fontSize: '0.95em',
                ml: '6px',
              }}
            />
          )}
        </Box>
        {share.editor && <Box>(edited by @{share.editor.username})</Box>}
        {title && <Box sx={{ fontWeight: 700 }}>{title}</Box>}
        {status !== itemStatuses.DRAFT_ADD && (
          <Box sx={{ fontSize: '0.9em' }}>
            posted: {toDateString(published_at)}
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {moveDown && (
            <Box sx={{ mr: 0.5 }}>
              <TooltipIconButton
                Icon={MoveDownIcon}
                onClick={moveDown.bind(null, item)}
                title="move down"
              />
            </Box>
          )}
          {moveUp && (
            <TooltipIconButton
              Icon={MoveUpIcon}
              onClick={moveUp.bind(null, item)}
              title="move up"
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mx: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {setItemStatus && status === itemStatuses.DRAFT_ADD && (
          <Box sx={{ mb: 1 }}>
            <TooltipIconButton
              Icon={UndoIcon}
              title="remove from draft"
              onClick={setItemStatus.bind(
                null,
                id,
                itemStatuses.SHARED_WITH_TR
              )}
              placement="left"
            />
          </Box>
        )}
        {setItemStatus && status === itemStatuses.PUBLISHED && (
          <Box sx={{ mb: 1 }}>
            <TooltipIconButton
              Icon={RemoveIcon}
              title="remove from story"
              onClick={setItemStatus.bind(null, id, itemStatuses.DRAFT_DELETE)}
              placement="left"
            />
          </Box>
        )}
        {setItemStatus && status === itemStatuses.DRAFT_DELETE && (
          <Box sx={{ mb: 1 }}>
            <TooltipIconButton
              Icon={UndoIcon}
              title="undo remove from story"
              onClick={setItemStatus.bind(null, id, itemStatuses.PUBLISHED)}
              placement="left"
            />
          </Box>
        )}
        <Box>
          <TooltipIconButton
            Icon={WatchIcon}
            title="watch share"
            onClick={() => openModal('sharePlayer', { shareCode: share.code })}
            placement="left"
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <CopyToClipboardButton
            title="copy share link"
            data={`${window.location.origin}/s/${share.code}`}
            placement="left"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ListItem
