import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/team_reclip_story`

export const getStoryMeta = async () => {
  try {
    const url = `${BASE_URL}/meta`
    const { data: results } = await axios.get(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const setShareDraftWithStaff = async (shareWithStaff = false) => {
  try {
    const url = `${BASE_URL}/share_draft_with_staff/${+shareWithStaff}`
    const { data: results } = await axios.put(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const searchStoryItems = async (query = {}) => {
  try {
    const url = `${BASE_URL}/search`
    const { data: results } = await axios.post(url, query)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const exportStoryItems = async (filters = {}) => {
  try {
    const url = `${BASE_URL}/search/export`
    const { data } = await axios.post(url, filters)
    return data.url
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getStoryItems = async () => {
  try {
    const url = `${BASE_URL}/items`
    const { data: results } = await axios.get(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const setItemStatus = async (itemId, status) => {
  try {
    const url = `${BASE_URL}/items/${itemId}/status/${status}`
    const { data: results } = await axios.put(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const deleteItem = async (itemId) => {
  try {
    const url = `${BASE_URL}/items/${itemId}`
    const { data } = await axios.delete(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const updateDraftOrder = async (itemIds) => {
  try {
    const url = `${BASE_URL}/items/update_draft_order`
    const { data: results } = await axios.put(url, { item_ids: itemIds })
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const setConsentToPublish = async (itemId, consentGiven) => {
  try {
    const url = `${BASE_URL}/items/${itemId}/consent/${+consentGiven}`
    const { data: results } = await axios.put(url)
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const publish = async (sendNotification) => {
  try {
    const url = `${BASE_URL}/publish`
    const { data: results } = await axios.put(url, {
      send_notification: sendNotification,
    })
    return results
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
