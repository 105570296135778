import * as React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, disabled }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: disabled ? 'red' : 'text.primary',
    fontSize: '0.9em',
    fontWeight: 700,
  },
}))

const TooltipIconButton = ({
  Icon,
  title,
  onClick,
  disabled = false,
  placement = 'top',
  sx = {},
}) => (
  <StyledTooltip title={title} placement={placement}>
    <span>
      <IconButton size="small" onClick={onClick} disabled={disabled}>
        <Icon sx={{ fontSize: '0.9em', ...sx }} />
      </IconButton>
    </span>
  </StyledTooltip>
)

export const TooltipIcon = ({
  Icon,
  title,
  onClick,
  placement = 'top',
  sx = {},
}) => (
  <StyledTooltip title={title} placement={placement}>
    <Icon
      sx={{ fontSize: '0.9em', cursor: 'pointer', ...sx }}
      onClick={onClick}
    />
  </StyledTooltip>
)

export default TooltipIconButton
