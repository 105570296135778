import { useCallback, useEffect } from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import api from 'services/api'
import useModalsActions from 'store/actions/modals'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'

const Message = ({ message, openModal }) => {
  const getRunSteps = useCallback(
    (messageId) => {
      openModal('jsonViewer', {
        dataPromise: api.chatbots.getRunSteps(messageId),
        loaderText: 'loading run data',
      })
    },
    [openModal]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          alignSelf: message.role === 'assistant' ? 'flex-start' : 'flex-end',
          backgroundColor:
            message.role === 'assistant' ? 'background.paper' : '#0078ff',
          py: 1,
          pl: 1.5,
          pr: message.role === 'assistant' ? 5 : 1.5,
          borderRadius: '4px',
          maxWidth: '75%',
          mb: 1.5,
          whiteSpace: 'pre-wrap',
          borderWidth: 1,
          borderColor: 'transparent',
          borderStyle: 'solid',
          cursor: 'default',
          position: 'relative',
        }}
      >
        {message.content}
        {message.role === 'assistant' && (
          <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
            <IconButton
              size="small"
              onClick={getRunSteps.bind(null, message.id)}
            >
              <SettingsIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Thread = ({ thread, messages, refreshMessages }) => {
  const { openModal } = useModalsActions()

  useEffect(() => {
    if (!thread || !messages) return

    const timer = setInterval(refreshMessages, 3000)
    return () => clearInterval(timer)
  }, [thread, messages, refreshMessages])

  if (!messages) return <Box>No messages.</Box>

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ fontWeight: 700 }}>{thread.id}</Box>
        <IconButton
          onClick={() =>
            openModal('jsonViewer', {
              dataPromise: api.chatbots.getThread(thread.id),
              loaderText: 'loading thread data',
            })
          }
        >
          <SettingsIcon />
        </IconButton>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box
        sx={{
          flex: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            overflow: 'scroll',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          {messages.map((message) => (
            <Message key={message.id} message={message} openModal={openModal} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Thread
