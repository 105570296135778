import { useState, useEffect, useCallback } from 'react'
import api from 'services/api'

const INITIAL_SEARCH_PARAMS = {
  thread_id: null,
  include_test_threads: false,
  include_threads_without_user_engagement: false,
  limit: 100,
  before_date: null,
  after_date: null,
}

export default function useThreads(chatbotSlug) {
  const [searchParams, setSearchParams] = useState(INITIAL_SEARCH_PARAMS)
  const [threads, setThreads] = useState(null)
  const [selectedThreadId, setSelectedThreadId] = useState(null)
  const [messages, setMessages] = useState(null)

  const selectedThread = threads?.find((t) => t.id === selectedThreadId)

  const loadThreads = useCallback((slug, params) => {
    api.chatbots
      .searchThreads({ chatbot_slug: slug, ...params })
      .then(setThreads)
  }, [])

  const loadMessages = useCallback((threadId) => {
    if (!threadId) {
      setMessages(null)
      return
    }

    api.chatbots.getMessages(threadId).then(setMessages)
  }, [])

  const onSelectThread = useCallback(
    (threadId) => {
      if (!threads) return

      setSelectedThreadId(threadId)
    },
    [threads]
  )

  useEffect(() => {
    if (!threads || threads.length === 0) {
      setSelectedThreadId(null)
      return
    }

    if (!selectedThreadId || !threads.find((t) => t.id === selectedThreadId))
      setSelectedThreadId(threads[0].id)
  }, [threads, selectedThreadId])

  useEffect(() => {
    loadThreads(chatbotSlug, searchParams)
  }, [loadThreads, chatbotSlug, searchParams])

  useEffect(() => {
    loadMessages(selectedThreadId)
  }, [loadMessages, selectedThreadId])

  return {
    threads,
    messages,
    selectedThread,
    onSelectThread,
    refreshThreads: () => loadThreads(chatbotSlug, searchParams),
    refreshMessages: () => loadMessages(selectedThread?.id),
    searchParams,
    setSearchParams,
  }
}
