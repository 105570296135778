import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Avatar from 'components/core/Avatar'
import * as dayjs from 'dayjs'

const ThreadPreview = ({ thread, selectedThread, onSelectThread }) => {
  const { user } = thread
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor:
          thread.id === selectedThread?.id ? 'primary.main' : 'divider',
        borderRadius: '6px',
        p: 1,
        userSelect: 'none',
        cursor: thread.id === selectedThread?.id ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor:
            thread.id === selectedThread?.id ? undefined : 'action.hover',
        },
      }}
      onClick={onSelectThread.bind(null, thread.id)}
      key={thread.id}
    >
      <Avatar name={user.full_name} image={user.picture} size={40} />
      <Box sx={{ ml: 1 }}>
        <Box sx={{ fontWeight: 700 }}>
          @{user.username} | {user.full_name}
        </Box>
        <Box sx={{ fontStyle: 'italic', fontSize: '0.9em' }}>
          {thread.is_test_thread
            ? 'test thread'
            : `user thread number: ${thread.user_thread_number}`}
        </Box>
        <Box sx={{ fontStyle: 'italic', fontSize: '0.9em' }}>
          created: {dayjs(thread.created_at + 'Z').format('M/D/YY h:mm:ss a')}
        </Box>
      </Box>
    </Box>
  )
}

const ThreadList = ({
  threads,
  selectedThread,
  onSelectThread,
  refreshThreads,
}) => {
  useEffect(() => {
    if (!threads) return

    const timer = setInterval(refreshThreads, 3000)
    return () => clearInterval(timer)
  }, [threads, refreshThreads])

  if (!threads) return null

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'auto',
      }}
    >
      {threads.length === 0 && <Box>No threads.</Box>}
      {threads.map((thread) => (
        <ThreadPreview
          key={thread.id}
          thread={thread}
          selectedThread={selectedThread}
          onSelectThread={onSelectThread}
        />
      ))}
    </Box>
  )
}

export default ThreadList
