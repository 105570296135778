import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/free_phone_numbers`

export const getFreePhoneNumbers = async () => {
  try {
    const { data } = await axios.get(BASE_URL)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getSmsCode = async (phoneNumber) => {
  try {
    const url = `${BASE_URL}/${phoneNumber}/sms_code`
    const { data } = await axios.get(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
