import { useEffect, useState, useRef, useCallback } from 'react'

const initialResults = {
  hits: [],
  num_hits: 0,
  processing_time_ms: 0,
  hasMore: false,
  error: null,
  isLoading: false,
  initialLoad: true,
}

export default function useSearchResults(search, params) {
  const [results, setResults] = useState(initialResults)
  const pending = useRef([])
  const filtersRef = useRef(null)

  const doSearch = useCallback(
    async (filters, page, limit, requestKey) => {
      try {
        setResults((results) => ({ ...results, isLoading: true }))
        const data = await search({ filters, page, limit })

        if (pending.current.includes(requestKey))
          setResults((results) => ({
            hits: page === 0 ? data.hits : [...results.hits, ...data.hits],
            numHits: data.num_hits,
            hasMore: page < data.num_pages - 1,
            processingTimeMs: data.processing_time_ms,
            error: null,
            isLoading: false,
            initialLoad: false,
          }))
      } catch (err) {
        setResults((results) => ({
          ...results,
          error: err.message,
          isLoading: false,
        }))
      } finally {
        pending.current = pending.current.filter((key) => key !== requestKey)
      }
    },
    [search]
  )

  useEffect(() => {
    const { filters, page, limit } = params

    if (!filters) {
      pending.current = []
      return setResults(initialResults)
    }

    if (filtersRef.current !== filters) {
      pending.current = []

      // note: the text field is special in that we don't start over when
      // the text field changes. maybe pass that fact in as an arg
      if (filters?.text === filtersRef.current?.text) setResults(initialResults)

      filtersRef.current = filters
    }

    const requestKey = Math.random()
    pending.current.push(requestKey)
    doSearch(filters, page, limit, requestKey)
  }, [params, doSearch])

  const updateHits = useCallback(
    (updates, idFinder = (hit) => hit.id) => {
      const newHits = results.hits.map((hit) => ({
        ...hit,
        ...(updates[idFinder(hit)] || {}),
      }))
      setResults({ ...results, hits: newHits })
    },
    [results]
  )

  const updateAllHits = useCallback(
    (updates) => {
      const newHits = results.hits.map((hit) => ({
        ...hit,
        ...updates,
      }))
      setResults({ ...results, hits: newHits })
    },
    [results]
  )

  const updateHitsFunc = useCallback(
    (updateFunc) => {
      const newHits = results.hits.map((hit) => updateFunc(hit))
      setResults({ ...results, hits: newHits })
    },
    [results]
  )

  const filterHits = useCallback(
    (filter) => {
      const newHits = results.hits.filter(filter)
      setResults(newHits)
    },
    [results]
  )

  return { results, updateHits, updateAllHits, updateHitsFunc, filterHits }
}
