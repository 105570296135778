import { useCallback } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AttachIcon from '@mui/icons-material/AttachFile'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const FileInput = ({ onSelect, title, sx = {} }) => {
  const onChange = useCallback(
    (e) => {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      onSelect(formData)
    },
    [onSelect]
  )

  return (
    <Box sx={sx}>
      <Button variant="outlined" component="label" fullWidth>
        {title}
        <input type="file" onChange={onChange} hidden />
      </Button>
    </Box>
  )
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, disabled }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: disabled ? 'red' : 'text.primary',
    fontSize: '0.9em',
    fontWeight: 700,
  },
}))

export const FileInputIcon = ({
  onSelect,
  sx = {},
  title,
  placement = 'top',
}) => {
  const onChange = useCallback(
    (e) => {
      const file = e.target.files[0]
      console.log('file', file)
      const formData = new FormData()
      formData.append('file', file)
      onSelect(formData)
    },
    [onSelect]
  )

  return (
    <>
      <StyledTooltip title={title} placement={placement}>
        <IconButton size="small" component="label">
          <AttachIcon sx={{ fontSize: '0.9em' }} />
          <input type="file" onChange={onChange} hidden />
        </IconButton>
      </StyledTooltip>
    </>
  )
}

export default FileInput
