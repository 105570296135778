import Box from '@mui/material/Box'
import Select from 'components/core/Select'

const ChatbotSelect = ({ chatbots, selectedChatbot, onSelectChatbot }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ fontWeight: 700, fontSize: '1.2em' }}>Chatbots</Box>
      <Select
        items={chatbots.map((chatbot) => ({
          id: chatbot.slug,
          label: chatbot.slug,
        }))}
        selectedItemId={selectedChatbot.slug}
        onSelect={onSelectChatbot}
        title="select bot"
        sx={{ width: '240px' }}
      />
    </Box>
  )
}

export default ChatbotSelect
