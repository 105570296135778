import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/chatbots`

export const getChatbots = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getChatbot = async (slug) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${slug}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const updateChatbot = async (chatbot) => {
  try {
    const { data } = await axios.put(`${BASE_URL}`, chatbot)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getThreads = async (slug) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${slug}/threads`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const searchThreads = async (params) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/threads/search`, params)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getThread = async (threadId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/threads/${threadId}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getMessages = async (threadId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/threads/${threadId}/messages`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getRunSteps = async (messageId) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/runs/${messageId}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
