import Profile from './Profile'
import ReclipPlayer from './ReclipPlayer'
import SharePlayer from './SharePlayer'
import JsonViewer from './JsonViewer'

const Modals = () => {
  return (
    <>
      <Profile />
      <ReclipPlayer />
      <SharePlayer />
      <JsonViewer />
    </>
  )
}

export default Modals
