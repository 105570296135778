import Box from '@mui/material/Box'

const Checkbox = ({ checked, onChange }) => {
  return (
    <Box
      sx={{
        '& input[type="checkbox"]': {
          width: '16px',
          height: '16px',
          cursor: 'pointer',
          backgroundColor: 'background.paper',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'divider',
          appearance: 'none',
          display: 'block',
          '&:checked': {
            accentColor: (theme) => theme.palette.primary.main,
            appearance: 'revert',
          },
        },
      }}
    >
      <input type="checkbox" checked={checked} onChange={onChange} />
    </Box>
  )
}

export default Checkbox
