import Box from '@mui/material/Box'
import OpenInNew from '@mui/icons-material/OpenInNew'
import * as dayjs from 'dayjs'
import LoadingIcon from 'components/core/LoadingIcon'
import TrashIcon from '@mui/icons-material/Delete'

const PhoneTable = ({ phones, deleteUser }) => {
  return (
    <Box
      sx={{
        pb: 2,
        fontSize: '0.9em',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
        },
        '& th, td': {
          padding: 1,
          textAlign: 'center',
        },
        '& td': {
          borderTopWidth: 1,
          borderTopColor: 'divider',
          borderTopStyle: 'solid',
        },
        '& td:nth-of-type(1)': {
          width: 150,
        },
        '& td:nth-of-type(2)': {
          textAlign: 'center',
          width: 120,
          p: 0,
        },
      }}
    >
      <table>
        <thead>
          <tr>
            <th>phone</th>
            <th>sms code</th>
            <th>account created at</th>
            <th>username</th>
            <th>delete account</th>
          </tr>
        </thead>
        <tbody>
          {phones.map(({ phone_number, user, sms_url }) => (
            <tr key={phone_number}>
              <td>{phone_number}</td>
              <td>
                <LoadingIcon
                  Icon={OpenInNew}
                  onClick={() => window.open(sms_url, '_blank')}
                />
              </td>
              <td>
                {user ? dayjs(user.created_at + 'Z').format('MMM D, YYYY') : ''}
              </td>
              <td>{user?.username}</td>
              <td>
                {user ? (
                  <LoadingIcon
                    Icon={TrashIcon}
                    onClick={deleteUser.bind(null, user.id)}
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  )
}

export default PhoneTable
