import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import Select from 'components/core/Select'
import Divider from '@mui/material/Divider'
import dayjs from 'dayjs'
import { coverPhotoTypes } from './const'
import Toggle from 'components/core/Toggle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const DATE_RANGES = [
  { id: '7', label: 'last 7 days' },
  { id: '30', label: 'last 30 days' },
  { id: 'all', label: 'all time' },
]

const COVER_PHOTO_TYPES = [
  { id: 'live', label: 'live' },
  { id: 'live-or-still', label: 'live or still' },
  { id: 'any', label: 'any' },
]

const Mask = ({ visible }) => (
  <Box
    sx={{
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      pointerEvents: 'all',
      backgroundColor: 'black',
      opacity: 0.2,
      display: visible ? 'block' : 'none',
    }}
  />
)

const Filters = ({ filters, setFilters }) => {
  const [dateRangeId, setDateRangeId] = useState(DATE_RANGES[2].id)
  const [coverPhotoTypesId, setCoverPhotoTypesId] = useState(
    COVER_PHOTO_TYPES[1].id
  )

  const handleTextChange = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        text: e.target.value,
        share_code: '',
      }))
    },
    [setFilters]
  )

  const handleShareCodeChange = useCallback(
    (e) => {
      const code = e.target.value.split('/').slice(-1)[0]
      setFilters((f) => ({
        ...f,
        share_code: code,
        text: '',
      }))
    },
    [setFilters]
  )

  const clearShareCode = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        share_code: '',
      }))
    },
    [setFilters]
  )

  const handleChange = useCallback(
    (field, value) => {
      setFilters((f) => ({
        ...f,
        [field]: value,
      }))
    },
    [setFilters]
  )

  const handleDateChange = useCallback(
    (id) => {
      setDateRangeId(id)

      const start_date = (() => {
        switch (id) {
          case '7':
            return dayjs().subtract(7, 'day')
          case '30':
            return dayjs().subtract(30, 'day')
          case 'all':
            return null
          default:
            return null
        }
      })()

      setFilters((f) => ({
        ...f,
        start_date,
        end_date: null,
      }))
    },
    [setFilters]
  )

  const handleConsentChange = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        consent_to_publish: e,
      }))
    },
    [setFilters]
  )

  const handleAnimatedVideoChange = useCallback(
    (e) => {
      setFilters((f) => ({
        ...f,
        has_animated_video: e,
      }))
    },
    [setFilters]
  )

  const handleCoverPhotoChange = useCallback(
    (id) => {
      setCoverPhotoTypesId(id)

      const cover_photo_types = (() => {
        switch (id) {
          case 'live':
            return [coverPhotoTypes.LIVE]
          case 'live-or-still':
            return [coverPhotoTypes.LIVE, coverPhotoTypes.STILL]
          case 'any':
            return [
              coverPhotoTypes.LIVE,
              coverPhotoTypes.STILL,
              coverPhotoTypes.NONE,
            ]
          default:
            return []
        }
      })()
      setFilters((f) => ({ ...f, cover_photo_types }))
    },
    [setFilters]
  )

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& input': {
            ml: 1,
            flex: 1,
            border: 0,
            outline: 0,
            backgroundColor: 'transparent',
            color: 'text.primary',
            fontSize: '20px',
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            px: 2,
            py: 1,
            borderWidth: 0,
          }}
        >
          <Mask visible={!!filters.share_code} />
          <SearchIcon sx={{ fontSize: '1.6em' }} />
          <input
            type="text"
            value={filters.text}
            onChange={handleTextChange}
            placeholder="search shared clips"
            autoFocus
          />
        </Box>
        <Box
          sx={{
            width: 300,
            display: 'flex',
            alignItems: 'center',
            pl: 2,
            pr: 1,
            py: 1,
          }}
        >
          <SearchIcon sx={{ fontSize: '1.6em' }} />
          <input
            type="text"
            value={filters.share_code}
            onChange={handleShareCodeChange}
            placeholder="share code or share link"
            spellCheck={false}
          />
          {filters.share_code && (
            <IconButton size="small" onClick={clearShareCode}>
              <CloseIcon sx={{ fontSize: 18 }}></CloseIcon>
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Mask visible={!!filters.share_code} />
        <Select
          items={DATE_RANGES}
          selectedItemId={dateRangeId}
          onSelect={handleDateChange}
          width={140}
          title="date range"
          sx={{ width: 140, mr: 3 }}
        />
        <Select
          items={[0, 5, 10, 20, 50].map((min) => ({
            id: min.toString(),
            label: `${min} words`,
          }))}
          selectedItemId={filters.min_words}
          onSelect={handleChange.bind(null, 'min_words')}
          title="min words"
          sx={{ width: 120, mr: 3 }}
        />
        <Select
          items={COVER_PHOTO_TYPES}
          selectedItemId={coverPhotoTypesId}
          onSelect={handleCoverPhotoChange}
          width={120}
          title="realPic"
          sx={{ width: 130, mr: 3 }}
        />
        <Toggle
          isChecked={filters.consent_to_publish}
          setIsChecked={handleConsentChange}
          label="consented only"
        />
        <Box sx={{ ml: 2 }}>
          <Toggle
            isChecked={filters.has_animated_video}
            setIsChecked={handleAnimatedVideoChange}
            label="has animated video"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Filters
