import { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import FileInput from 'components/core/FileInput'
import LoadingButton from '@mui/lab/LoadingButton'
import useToast from 'hooks/useToast'

const Harsh = () => {
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)
  const [isLoading, setisLoading] = useState(false)
  const toast = useToast()

  const createReclip = useCallback(async () => {
    setisLoading(true)

    let sourceId = null
    try {
      sourceId = await api.harsh.createReclip({ username, title })
    } catch (e) {
      toast({
        severity: 'error',
        message: e.toString(),
        autoHideDuration: 15000,
      })
      setisLoading(false)
      return
    }

    try {
      await api.reclips.uploadCoverPhoto({ sourceId, formData: file })
    } catch (e) {
      toast({
        severity: 'error',
        message: e.toString(),
        autoHideDuration: 15000,
      })
      setisLoading(false)
      return
    }

    try {
      await api.harsh.sendJustClipped({ sourceId })
      toast({ message: 'reclip created' })
      setUsername('')
      setTitle('')
      setFile(null)
    } catch (e) {
      toast({
        severity: 'error',
        message: e.toString(),
        autoHideDuration: 15000,
      })
    } finally {
      setisLoading(false)
    }
  }, [username, title, file, toast])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 300,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& input, textarea': {
            backgroundColor: 'transparent',
            p: 2,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'divider',
            borderRadius: 2,
            fontSize: '1em',
            mt: 2,
            width: '100%',
            resize: 'none',
          },
          '& textarea': {
            height: 120,
          },
        }}
      >
        <Box sx={{ textAlign: 'center', fontWeight: 700, fontSize: '1.4em' }}>
          Create a Reclip
        </Box>
        <input
          placeholder="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <textarea
          placeholder="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Box sx={{ width: 300 }}>
          <FileInput
            sx={{ mt: 2 }}
            title={file?.get('file')?.name || 'choose cover photo'}
            onSelect={setFile}
          />
        </Box>
        <LoadingButton
          disabled={!username || !title || !file}
          sx={{ mt: 2 }}
          variant="contained"
          onClick={createReclip}
          fullWidth
          loading={isLoading}
        >
          Create Reclip
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default Harsh
