import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import useThreads from './useThreads'
import ThreadList from './List'
import ThreadSearchParams from './SearchParams'
import Thread from './Thread'

const Threads = ({ chatbotSlug }) => {
  const {
    threads,
    messages,
    selectedThread,
    onSelectThread,
    refreshThreads,
    refreshMessages,
    searchParams,
    setSearchParams,
  } = useThreads(chatbotSlug)

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        sx={{
          width: 350,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ fontWeight: 700 }}>Threads</Box>
        <Divider sx={{ my: 1 }} />
        <ThreadSearchParams
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <Divider sx={{ my: 1 }} />
        <Box sx={{ flex: 1, position: 'relative' }}>
          {threads && (
            <ThreadList
              threads={threads}
              selectedThread={selectedThread}
              onSelectThread={onSelectThread}
              refreshThreads={refreshThreads}
            />
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" sx={{ mx: 4 }} />
      <Box sx={{ width: 700, height: '100%' }}>
        {selectedThread && (
          <Thread
            thread={selectedThread}
            messages={messages}
            refreshMessages={refreshMessages}
          />
        )}
      </Box>
    </Box>
  )
}

export default Threads
