import Box from '@mui/material/Box'
import ConfigRow from '../ConfigRow'
import Checkbox from '../Checkbox'

const ThreadSearchParams = ({ searchParams, setSearchParams }) => {
  return (
    <Box>
      <ConfigRow
        title="thread id"
        content={
          <Box
            sx={{
              backgroundColor: 'background.paper',
              borderRadius: '8px',
              '& input': {
                backgroundColor: 'transparent',
                border: 'none',
                width: 240,
                px: 1,
              },
            }}
          >
            <input
              type="text"
              autoCorrect="off"
              autoComplete="off"
              spellCheck={false}
              value={searchParams.thread_id || ''}
              onChange={(e) => {
                setSearchParams((params) => ({
                  ...params,
                  thread_id: e.target.value || null,
                }))
              }}
            />
          </Box>
        }
      />
      <ConfigRow
        title="show threads without user response"
        content={
          <Checkbox
            checked={searchParams.include_threads_without_user_engagement}
            onChange={() =>
              setSearchParams((params) => ({
                ...params,
                include_threads_without_user_engagement:
                  !params.include_threads_without_user_engagement,
              }))
            }
          />
        }
      />
      <ConfigRow
        title="show test threads"
        content={
          <Checkbox
            checked={searchParams.include_test_threads}
            onChange={() =>
              setSearchParams((params) => ({
                ...params,
                include_test_threads: !params.include_test_threads,
              }))
            }
          />
        }
      />
    </Box>
  )
}

export default ThreadSearchParams
