import Box from '@mui/material/Box'
import Loader from 'components/core/Loader'

const Publishing = ({ publishError }) => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Loader text="Publishing TR Story" error={publishError} />
    </Box>
  )
}

export default Publishing
