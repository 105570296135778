import { useCallback } from 'react'
import Box from '@mui/material/Box'

const Select = ({ items, selectedItemId, onSelect, title, sx = {} }) => {
  const handleChange = useCallback(
    (e) => {
      onSelect(e.target.value)
    },
    [onSelect]
  )

  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        ...sx,
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          color: 'text.primary',
          fontWeight: 700,
          textAlign: 'left',
        },
      }}
    >
      <select onChange={handleChange} value={selectedItemId}>
        {title && <option disabled>{title}</option>}
        {Object.values(items).map((item) => (
          <option key={item.id} value={item.id}>
            {item.label}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default Select
