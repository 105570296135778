import { useState, useEffect, useCallback } from 'react'
import useToast from 'hooks/useToast'
import api from 'services/api'

export default function useChatbots() {
  const [chatbots, setChatbots] = useState(null)
  const [selectedChatbotSlug, setSelectedChatbotSlug] = useState(null)
  const toast = useToast()

  const selectedChatbot = chatbots?.find((c) => c.slug === selectedChatbotSlug)

  const loadChatbots = useCallback(() => {
    api.chatbots.getChatbots().then(setChatbots)
  }, [])

  const onUpdateChatbot = useCallback(
    async (chatbot) => {
      try {
        const updatedChatbot = await api.chatbots.updateChatbot(chatbot)
        setChatbots((chatbots) =>
          chatbots.map((c) => {
            if (c.slug === updatedChatbot.slug) return updatedChatbot
            else return c
          })
        )
        toast({ message: `${chatbot.slug} updated.` })
      } catch (e) {
        toast({
          message: `Error updating ${chatbot.slug}: ${e}`,
          severity: 'error',
        })
      }
    },
    [toast]
  )

  const onSelectChatbot = useCallback(
    (slug) => {
      if (!chatbots) return

      setSelectedChatbotSlug(slug)
    },
    [chatbots]
  )

  useEffect(loadChatbots, [loadChatbots])

  useEffect(() => {
    if (!chatbots || chatbots.length === 0) {
      setSelectedChatbotSlug(null)
      return
    }

    if (!selectedChatbotSlug) setSelectedChatbotSlug(chatbots[0].slug)
  }, [chatbots, selectedChatbotSlug])

  return {
    chatbots,
    selectedChatbot,
    onUpdateChatbot,
    onSelectChatbot,
  }
}
